import { FakeRequest } from '../../../api/FakeRequest';
import { mockApi } from '../../../api/constants';
import { HttpRequest } from '../../../api/HttpRequest';
import { serializer } from '../../../dto';

export const editDepositRequest = !mockApi
    ? new HttpRequest<{ id: string; alias: string; goal?: number }, unknown>({
          method: 'put',
          path: () => `/deposits/`,
          body: input => serializer.serializeObject(input) || {},
          mapper: () => {},
      })
    : new FakeRequest<{ id: string; alias: string; goal?: number }, unknown>({
          fakeData: () => {},
      });
