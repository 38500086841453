import * as yup from 'yup';
import { isValidEmail, isValidPhone } from '../../../utils/validators';
import { FormikConfig } from 'formik';
import { Profile, User } from '../../../dto';

export type IProfileValues = {
    name: string;
    surname: string;
    patronymic: string;
    email: string;
    phone: string;

    sex: string;
    birthDate: Date | null;

    passportSeria: string;
    passportNumber: string;
    issuedBy: string;
    issuedAt: Date | null;
    passportDptCode: string;

    birthPlace: string;
    regAddress: string;
    liveAddress: string;
    TIN: string;
    SNILS: string;

    // scans
    passportMain: File | null;
    passportRegistration: File | null;
    passportSelfie: File | null;

    SNILSScan: File | null;
    bankInfoScan: File | null;

    bankName: string;
    BIK: string;
    bankAccount: string;
    correspondentAccount: string;
};

export const getProfileFormik = (
    user: User,
    profile: Profile | null,
    submit: (v: IProfileValues) => Promise<unknown>,
): FormikConfig<IProfileValues> => {
    return {
        initialValues: {
            name: user.name ?? '',
            surname: user.surname ?? '',
            patronymic: user.patronymic || '',
            email: user.email ?? '',
            phone: user.phone ?? '',

            sex: profile?.sex || 'M',
            birthDate: profile?.birthDate || null,

            // passport
            passportSeria: profile?.passportSeria || '',
            passportNumber: profile?.passportNumber || '',
            issuedBy: profile?.issuedBy || '',
            issuedAt: profile?.issuedAt || null,
            passportDptCode: profile?.passportDptCode || '',

            birthPlace: profile?.birthPlace || '',
            regAddress: profile?.regAddress || '',
            liveAddress: profile?.liveAddress || '',
            TIN: profile?.TIN || '',
            SNILS: profile?.SNILS || '',

            // scans
            passportMain: null,
            passportRegistration: null,
            passportSelfie: null,

            SNILSScan: null,
            bankInfoScan: null,

            // bank
            bankName: profile?.bankInfo.bankName || '',
            BIK: profile?.bankInfo.BIK || '',
            bankAccount: profile?.bankInfo.account || '',
            correspondentAccount: profile?.bankInfo.correspondentAccount || '',
        },
        onSubmit: async values => {
            return submit(values);
        },
        validationSchema: yup.object().shape({
            name: yup.string().required('Укажите имя'),
            surname: yup.string().required('Укажите фамилию'),
            phone: yup
                .string()
                .test('required and valid', 'Укажите номер телефона в корректном формате', function (phone) {
                    return isValidPhone(phone);
                }),
            email: yup
                .string()
                .test('required and valid', 'Укажите электронную почту в корректном формате', function (email) {
                    return isValidEmail(email);
                }),
            birthDate: yup.date().required('Поле обязательно к заполнению'),
            passportSeria: yup.string().required('Поле обязательно к заполнению'),
            passportNumber: yup.string().required('Поле обязательно к заполнению'),
            issuedBy: yup.string().required('Поле обязательно к заполнению'),
            issuedAt: yup.date().nullable().required('Поле обязательно к заполнению'),
            passportDptCode: yup.string().required('Поле обязательно к заполнению'),
            birthPlace: yup.string().required('Поле обязательно к заполнению'),
            regAddress: yup.string().required('Поле обязательно к заполнению'),
            liveAddress: yup.string().required('Поле обязательно к заполнению'),
            TIN: yup.string().optional(),
            SNILS: yup.string().optional(),

            BIK: yup.string().optional(),
            bankAccount: yup.string().optional(),
            bankName: yup.string().optional(),
            correspondentAccount: yup.string().optional(),

            ...(!profile && {
                passportMain: yup.mixed().required('Поле обязательно к заполнению'),
                passportSelfie: yup.mixed().required('Поле обязательно к заполнению'),
            }),
        }),
    };
};
