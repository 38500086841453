import React, { FC, useMemo, useState } from 'react';
import { Box, Grid, styled, TextField } from '@mui/material';
import { formatCurrency, formatPercent } from '../../../utils/formatters';
import { Text } from '../../../components/Text';
import { Colors } from '../../../theme/colors';
import { AddCircleOutline, PieChartOutline, WalletOutlined, WorkOutline } from '@mui/icons-material';
import { BeveledContainer } from '../../../components/BeveledContainer';
import { isDepositActive } from '../../../utils/deposits';
import { DepositDetailedInfo } from '../../../dto';
import { PER_MONTH_DEPOSIT_TYPE_NAME, END_TERM_DEPOSIT_TYPE_NAME } from '../../../store/constants';
import { BriefcaseIcon, ChartPieSliceIcon, PlusCircleIcon, WalletIcon } from '../../../components/Icons';
import { Edit, Done, Close } from '@mui/icons-material';
import { errorHandler } from '../../../api/apiHelpers';
import { editDepositRequest } from './editDepositAliasRequest';


type Props = {
    deposit: DepositDetailedInfo;
};

const Root = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '25px',
});

const Row = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
});

type CardProps = {
    name: string;
    icon: React.ReactNode;
    value: string;
};

const ParamCard: FC<CardProps> = ({ name, icon, value }) => {
    return (
        <BeveledContainer style={{ height: '100%', boxSizing: 'border-box'}}>
            <Row>
                <Text variant="Small">{name}</Text>
                {icon}
            </Row>
            <Text variant="LargeTitle" color={Colors.darkBlue}>
                {value}
            </Text>
        </BeveledContainer>
    );
};


const EditGoalParamCard: FC<Props> = ({ deposit }) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [newGoal, setNewGoal] = useState<number | undefined>(deposit.goal);

    const handleCancel = () => {
        setNewGoal(deposit.goal);
        setIsEditing(false);
    };

    const handleChange = async () => {
        try {
            if (newGoal === deposit.goal) {
                setIsEditing(false);
                return;
            }
            await editDepositRequest.request({ alias: deposit.alias, goal: newGoal , id: deposit.id });

            setIsEditing(false);
        } catch (error) {
            errorHandler(error);
        }
    };
    return (
        <BeveledContainer style={{ height: '100%', boxSizing: 'border-box'}}>
            <Row>
                <Text variant="Small">Цель вашей Инвесткопилки</Text>
                <ChartPieSliceIcon style={{ flexShrink: 0}} />
            </Row>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                {!isEditing ? (
                    <>
                        <Text variant="LargeTitle" color={Colors.darkBlue}>
                            {formatCurrency(newGoal)}
                        </Text>
                        <EditIcon onClick={() => setIsEditing(true)} />
                    </>
                ) : (
                    <>
                        <TextField type="number" variant="standard" value={newGoal} onChange={e => setNewGoal(Number(e.target.value))} />
                        <DoneIcon onClick={handleChange} />
                        <CloseIcon onClick={handleCancel} />
                    </>
                )}
            </Box>
        </BeveledContainer>
    );
};

const EditIcon = styled(Edit)(({ theme }) => ({
    color: theme.palette.primary.main,
    cursor: 'pointer',
}));

const DoneIcon = styled(Done)(({ theme }) => ({
    color: theme.palette.primary.main,
    cursor: 'pointer',
}));

const CloseIcon = styled(Close)(({ theme }) => ({
    cursor: 'pointer',
}));

const PerMonthDepositParams: FC<Props> = ({ deposit }) => {
    return (
        <Root>
            <Grid container spacing={'15px'}>
                <Grid item xs={6}>
                    <ParamCard
                        name={'Общий баланс Инвесткопилки'}
                        icon={<WalletIcon style={{ flexShrink: 0}} />}
                        value={formatCurrency(deposit.sumTotal)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ParamCard
                        name={'Заработано за месяц'}
                        icon={<ChartPieSliceIcon style={{ flexShrink: 0}} />}
                        value={formatCurrency(deposit.lastMonthProfit)}
                    />
                </Grid>
                <Grid item xs={12} >
                    <ParamCard
                        name={'Уже выплачено'}
                        icon={<BriefcaseIcon style={{ flexShrink: 0}} />}
                        value={formatCurrency(deposit.alreadyPaid)}
                    />
                </Grid>
            </Grid>
        </Root>
    )
}

const EndTermDepositParams: FC<Props> = ({ deposit }) => { 
    return (
        <Root>
            <Grid container spacing={'15px'}>
                <Grid item xs={6}>
                    <ParamCard
                        name={'Общий баланс Инвесткопилки'}
                        icon={<WalletIcon style={{ flexShrink: 0}} />}
                        value={formatCurrency(deposit.sumTotal)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <EditGoalParamCard
                        deposit={deposit}
                    />
                </Grid>
                <Grid item xs={6} >
                    <ParamCard
                        name={'Вы внесли всего'}
                        icon={<BriefcaseIcon style={{ flexShrink: 0}} />}
                        value={formatCurrency(deposit.sum)}
                    />
                </Grid>
                <Grid item xs={6} >
                    <ParamCard
                        name={'Инвесткопилка уже добавила'}
                        icon={<PlusCircleIcon style={{ flexShrink: 0}} />}
                        value={`+ ${formatCurrency(deposit.sumTotal - deposit.sum)}`}
                    />
                </Grid>
            </Grid>
        </Root>
    )
}

export const DepositParams: FC<Props> = ({ deposit }) => {
    if (deposit.name === PER_MONTH_DEPOSIT_TYPE_NAME) {
        return (
            <PerMonthDepositParams deposit={deposit} />
        )
    }

    if (deposit.name === END_TERM_DEPOSIT_TYPE_NAME) {
        return (
            <EndTermDepositParams deposit={deposit} />
        )
    }
    return (
        <Root>
            <Grid container spacing={'15px'}>
                <Grid item xs={6}>
                    <ParamCard
                        name={'Ваши пополнения'}
                        icon={<WorkOutline />}
                        value={formatCurrency(deposit.sum || deposit.firstPayment)}
                    />
                </Grid>
                {isDepositActive(deposit) && (
                    <>
                        <Grid item xs={6}>
                            <ParamCard
                                name={'Ваш баланс'}
                                icon={<WalletOutlined />}
                                value={formatCurrency(deposit.sumTotal)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ParamCard
                                name={'Ваш доход за месяц'}
                                icon={<AddCircleOutline />}
                                value={`+ ${formatCurrency(deposit.lastMonthProfit)}`}
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={6}>
                    <ParamCard
                        name={'Доходность ваших инвестиций'}
                        icon={<PieChartOutline />}
                        value={formatPercent(deposit.profitPercent)}
                    />
                </Grid>
            </Grid>
        </Root>
    );
};
