import { JsonObject, JsonProperty } from 'typescript-json-serializer';

@JsonObject()
export class DepositInput {
    @JsonProperty({ name: 'deposit_type' })
    depositId: string = '';

    @JsonProperty()
    duration: number = 0;

    @JsonProperty({ name: 'first_payment' })
    firstPayment: number = 0;

    @JsonProperty({ name: 'alias' })
    alias: string = '';

    @JsonProperty()
    goal: number | undefined = undefined;
}

export const getDepositInput = (input: DepositInput): DepositInput => Object.assign(new DepositInput(), input);
