import { JsonObject, JsonProperty } from 'typescript-json-serializer';
import { DEFAULT_DATE, JsonDate, JsonEnum } from './util';
import { DocumentStatus } from '../types';

@JsonObject()
export class FileInfo {
    @JsonProperty()
    name: string = '';

    @JsonEnum({ type: DocumentStatus })
    status: DocumentStatus = DocumentStatus.DENIED;
}

@JsonObject()
class PassportScans {
    @JsonProperty({ type: FileInfo })
    main: FileInfo = new FileInfo();

    @JsonProperty({ type: FileInfo })
    registration: FileInfo = new FileInfo();

    @JsonProperty({ type: FileInfo })
    marriage: FileInfo = new FileInfo();

    @JsonProperty({ type: FileInfo, name: 'prev_passport' })
    prevPassport: FileInfo = new FileInfo();

    @JsonProperty({ type: FileInfo })
    selfie: FileInfo = new FileInfo();
}

@JsonObject()
class BankInfo {
    @JsonProperty({ name: 'bank_name' })
    bankName: string | null = null;

    @JsonProperty({ name: 'bik' })
    BIK: string = '';

    // TODO Убрать алиас после исправления серверного API
    @JsonProperty({ name: 'payment_account' })
    account: string | null = null;

    @JsonProperty({ name: 'correspondent_account' })
    correspondentAccount: string | null = null;
}

@JsonObject()
export class Profile {
    @JsonProperty({ name: 'first_name' })
    name: string = '';

    @JsonProperty({ name: 'last_name' })
    surname: string = '';

    @JsonProperty({ name: 'middle_name' })
    patronymic: string | null | undefined;

    @JsonProperty()
    phone: string = '';

    @JsonProperty()
    sex: string = '';

    @JsonDate({ name: 'birth_date' })
    birthDate: Date = DEFAULT_DATE;

    @JsonProperty({ name: 'passport_seria' })
    passportSeria: string = '';

    @JsonProperty({ name: 'passport_number' })
    passportNumber: string = '';

    @JsonProperty({ name: 'issued_by' })
    issuedBy: string = '';

    @JsonDate({ name: 'issued_at' })
    issuedAt: Date = DEFAULT_DATE;

    @JsonProperty({ name: 'passport_dpt_code' })
    passportDptCode: string = '';

    @JsonProperty({ name: 'birth_place' })
    birthPlace: string = '';

    @JsonProperty({ name: 'reg_address' })
    regAddress: string = '';

    @JsonProperty({ name: 'live_address' })
    liveAddress: string = '';

    @JsonProperty({ name: 'tin' })
    TIN: string | null = null;

    @JsonProperty({ name: 'snils' })
    SNILS: string | null = null;

    @JsonProperty({ type: PassportScans, name: 'passport_scans' })
    passportScans: PassportScans = new PassportScans();

    @JsonProperty({ type: FileInfo, name: 'snils_scan' })
    SNILSScan: FileInfo = new FileInfo();

    @JsonProperty({ type: FileInfo, name: 'tin_scan' })
    TINScan: FileInfo = new FileInfo();

    @JsonProperty({ type: FileInfo, name: 'account_scan' })
    bankInfoScan: FileInfo = new FileInfo();

    @JsonProperty({ type: BankInfo, name: 'bank_info' })
    bankInfo: BankInfo = new BankInfo();
}
