import { DepositStatus } from '../types';
import { JsonObject, JsonProperty } from 'typescript-json-serializer';
import { DEFAULT_DATE, JsonEnum, JsonDate } from './util';

@JsonObject()
export class DepositInfo {
    @JsonProperty()
    id: string = '';

    @JsonProperty()
    name: string = '';

    @JsonProperty({ name: 'first_payment' })
    firstPayment: number = 0;

    @JsonProperty()
    sum: number = 0;

    @JsonProperty({ name: 'sum_total' })
    sumTotal: number = 0;

    @JsonProperty()
    dividends: number = 0;

    @JsonProperty({ name: 'last_month_profit' })
    lastMonthProfit: number = 0;

    @JsonProperty({ name: 'profit_percent' })
    profitPercent: number = 0;

    @JsonProperty()
    duration: number = 0;

    @JsonDate({ name: 'created_at' })
    createdAt: Date = DEFAULT_DATE;

    @JsonEnum({ type: DepositStatus })
    status: DepositStatus = DepositStatus.DRAFT;

    @JsonProperty({ name: 'type_icon' })
    typeIcon: string = '';

    @JsonProperty({ name: 'alias' })
    alias: string = '';

    @JsonProperty()
    goal: number | undefined = undefined;
}
